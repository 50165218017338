import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Source } from '../models/source';
import { ApiManagerService } from './api-manager.service';

@Injectable({
    providedIn: 'root'
})
export class SourcesService {
    private sourcesUrl = 'sources';
    private host: string;

    constructor(
        private http: HttpClient,
        private apiSvc: ApiManagerService,
    ) {
        this.host = this.apiSvc.getApiHostBase();
    }

    getSources(): Observable<Source[]> {
        return this.http.get<Source[]>(this.host + this.sourcesUrl);
    }
}
