import { Component, OnInit, ElementRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Meta } from '@angular/platform-browser';
import { BenefitsService } from './../../services/benefits.service';
import { Benefit } from './../../models/benefit';
import { Subject } from 'rxjs';

@Component({
    selector: 'program',
    templateUrl: './program.component.html',
    styleUrls: ['./program.component.scss']
})
export class ProgramComponent implements OnInit {
    program: Subject<Benefit[]>;
    showInterested = false;

    constructor(
        private titleService: Title,
        private meta: Meta,
        private benefitsSvc: BenefitsService,
        private elRef: ElementRef
    ) {
        this.meta.updateTag({
            name: 'description',
            // tslint:disable-next-line: max-line-length
            content: 'NISSAN Technician Training Academy (NTTA) benefits include factory-specific training, NISSAN and ASE certification, and apprenticeship opportunities.',
        });
        this.program = new Subject<Benefit[]>();
    }

    ngOnInit() {
        this.benefitsSvc.getBenefits(2).subscribe(data => {
            this.program.next(data);

            setTimeout(() => {
                const el = this.elRef.nativeElement.querySelector('.onSetInterested');
                if (el) {
                    el.addEventListener('click', this.onSetInterested.bind(this));
                }
            }, 10);
        });

        this.titleService.setTitle('The Program | Nissan Technician Training Academy');
    }

    onSetInterested(agreed: boolean) {
        this.showInterested = agreed;
    }
}