import { Component, OnInit } from '@angular/core';
import { Stat } from './../../models/stat';
import { Subject } from 'rxjs';
import { StatsService } from './../../services/stats.service';

@Component({
	selector: 'home-promos2',
	templateUrl: './home-promos2.component.html',
	styleUrls: ['./home-promos2.component.scss']
})
export class HomePromos2Component implements OnInit {
	stat: Subject<Stat[]>;

	constructor(
		private statSvc: StatsService,
	) {
		this.stat = new Subject<Stat[]>();
	}

	ngOnInit() {
		this.statSvc.getStats().subscribe(data => {
			this.stat.next(data);
		});
	}
}
