import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Route, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TokenUserDataProviderService } from './token-user.data-provider.service';

@Injectable({
    providedIn: 'root',
})
export class DealerPortalAuthGuard implements CanActivate, CanLoad {
    constructor(
        private router: Router,
        private userSvc: TokenUserDataProviderService,
    ) { }

    private getStoredToken(): string {
        const storedToken = localStorage.getItem('token');
        return storedToken;
    }

    public canActivate(): boolean | Promise<boolean> | Observable<boolean> {
        // routing happens after AppComponent has converted queryParam to localstorage
        const storedToken = this.getStoredToken();
        // if no token at all: bounce to nna.net
        if (!storedToken) {
            this.userSvc.denyAccess();
            return false;
        }
        // else (localstorage token): return true
        return true;
    }

    public canLoad() {
        const canActivate = this.canActivate();
        // deny if no token
        if (!canActivate) {
            return canActivate;
        }

        const url = window.location.href;
        const isBranch = /dealerportal\/[^\?]+/.test(url);
        // reroute to dealer portal root if not going there
        if (isBranch) {
            this.router.navigate(['/dealerportal'], {queryParamsHandling: 'preserve'});
            return false;
        }
        // otherwise go ahead
        return canActivate;
    }
}
