import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'home-get-started',
	templateUrl: './home-get-started.component.html',
	styleUrls: ['./home-get-started.component.scss']
})
export class HomeGetStartedComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}
}
