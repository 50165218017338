import { Testimonial } from '../models/testimonial';

export const TESTIMONIALS: Testimonial[] = [
  { id: 1,
    quote: '“I don’t just have a job, I have a career.”',
    name: 'Darius Anderson',
    title: 'Service Technician, INFINITI of Cool Springs',
    posterImage: '/assets/images/Darius_NTTA_Promo_Video_V2-poster.jpg',
    videoFiles: [
      {fileUrl: 'Darius_NTTA_Promo_Video_V2.webm', fileType: 'video/webm'},
      {fileUrl: 'Darius_NTTA_Promo_Video_V2.mp4', fileType: 'video/mp4'},
    ],
  },
  { id: 2,
    quote: '',
    name: '',
    title: '',
    posterImage: '/assets/images/vincente_graduates_video-poster.jpg',
    videoFiles: [
      {fileUrl: 'vincente_graduates_video.webm', fileType: 'video/webm'},
      {fileUrl: 'vincente_graduates_video.mp4', fileType: 'video/mp4'},
    ],
  },
  { id: 3,
    quote: '“Going through the apprenticeship program, students get hands on experience… So they’re more prepared. It’s an advantage and a leg up.”',
    name: 'Vincente Montero',
    title: 'Auto Technician Instructor, Bronx Community College',
    posterImage: '/assets/images/vincente_apprentice_video-poster.jpg',
    videoFiles: [
      {fileUrl: 'vincente_apprentice_video.webm', fileType: 'video/webm'},
      {fileUrl: 'vincente_apprentice_video.mp4', fileType: 'video/mp4'},
    ],
  }
];