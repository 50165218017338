import { Component, OnInit, Input } from '@angular/core';
import { GoogleAnalyticsEventTrackingService } from '../../services/google-analytics-event-tracking.service';
import { Benefit } from './../../models/benefit';
import { Observable } from 'rxjs';

@Component({
	selector: 'home-promos',
	templateUrl: './home-promos.component.html',
	styleUrls: ['./home-promos.component.scss'],
	providers: [GoogleAnalyticsEventTrackingService]
})
export class HomePromosComponent implements OnInit {
	@Input() content: Observable<Benefit[]>;

	constructor(
		private gaEventTrackingSvc: GoogleAnalyticsEventTrackingService
	) { }

	ngOnInit() {
	}

	public _trackEvent(component: string, destination: string, element: string, category: string) {
		this.gaEventTrackingSvc._trackEvent(component, destination, element, category);
	}
}
