import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ApiAuthService } from './api-auth.service';
import { ApiManagerService } from './api-manager.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private authSvc: ApiAuthService,
        private apiSvc: ApiManagerService,
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // bypass requests to anyone but our API server
        if (!this.apiSvc.getIsLoaded() || !req.url.includes(this.apiSvc.getApiHostBase())) {
            return next.handle(req);
        }

        // Get the auth token
        const token = this.authSvc.apiToken;

        // Clone the request to add the new header.
        const authReq = req.clone({
            headers: req.headers
                .append('Authorization', 'Bearer ' + token)
        });

        // Pass on the cloned request instead of the original request.
        return next.handle(authReq).pipe(tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                // do stuff with response if you want
            }
        }, (err: any) => {
            if (err instanceof HttpErrorResponse) {
                if (err.status === 401) {
                    console.error('request auth failed');
                    // this.router.navigateByUrl('/user/login');
                }
            }
        }));
    }
}