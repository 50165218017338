import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NttaAppConfig } from '../models/ntta-app-config';
import { NttaAppConfigFile } from '../models/ntta-app-config-file';

@Injectable({
    providedIn: 'root'
})
export class AppConfigService {
    private appConfig: NttaAppConfig;

    constructor(
        private injector: Injector,
    ) {
    }

    async loadAppConfig(): Promise<NttaAppConfig> {
        const http = this.injector.get(HttpClient);

        return http.get<NttaAppConfigFile>('/assets/app-config.json')
            .toPromise()
            .then(data => {
                const curEnv = data.currentEnvironment;
                this.appConfig = data[curEnv];
                return this.appConfig;
            });
    }

    get config() {
        return this.appConfig;
    }
}
