import { Component, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild } from '@angular/core';
import { GoogleAnalyticsEventTrackingService } from '../../services/google-analytics-event-tracking.service';
import { Testimonial } from '../../models/testimonial';
import { Observable } from 'rxjs';
import { AppConfigService } from '../../services/app-config.service';

@Component({
    selector: 'testimonial-video',
    templateUrl: './testimonial-video.component.html',
    styleUrls: ['./testimonial-video.component.scss']
})
export class TestimonialVideoComponent implements OnInit {
    @Input() testimonialData: Testimonial;
    @Input() activeVideoId$: Observable<number>;
    @Output() playStarted: EventEmitter<number> = new EventEmitter<number>();
    @ViewChild('overlay', { static: true }) overlayElem: ElementRef<HTMLDivElement>;
    @ViewChild('videoPlayer', { static: true }) videoElem: ElementRef<HTMLVideoElement>;
    @HostBinding('style.minHeight.px') minHeightPx = '0';
    public videoUrlBase: string;

    constructor(
        private gaEventTrackingSvc: GoogleAnalyticsEventTrackingService,
        private environment: AppConfigService,
    ) { }

    ngOnInit() {
        if (!!this.activeVideoId$) {
            this.activeVideoId$.subscribe(activeVideoId => {
                if (activeVideoId !== this.testimonialData.id && this.videoElem.nativeElement) {
                    this.videoElem.nativeElement.pause();
                }
            })
        }
        this.videoUrlBase = this.environment.config.asset_storage_host_base + 'videos/';
    }

    ngAfterViewInit() {
        // hack for ie11 cached images
        if (this.overlayElem.nativeElement.querySelector('img').complete) {
            this.posterLoaded();
        }
    }

    posterLoaded() {
        if (this.overlayElem && this.overlayElem.nativeElement) {
            console.log('overlay: ', this.overlayElem.nativeElement.querySelector('img').clientHeight);
            this.minHeightPx = '' + this.overlayElem.nativeElement.querySelector('img').clientHeight;
        }
    }

    posterClicked() {
        this.overlayElem.nativeElement.style.display = 'none';
        if (!!this.videoElem && !!this.videoElem.nativeElement) {
            this.videoElem.nativeElement.play();
        }
        
        !!this.testimonialData && !!this.testimonialData.videoFiles[0] &&
            this.gaEventTrackingSvc._trackEvent(null, null, 'testimonial video: ' + this.testimonialData.videoFiles[0].fileUrl, 'video');
    }

    onPlay() {
        this.playStarted.emit(this.testimonialData.id);
    }
}
