import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class GoogleMapsService {
    private googleMapsUrl = 'https://maps.googleapis.com/maps/api/geocode/json?';

    constructor(private http: HttpClient) { }

    getCoordinates(address): Observable<any> {
        return this.http.get<any>(this.googleMapsUrl + 'address=' + address + '&key=' + environment.google_maps_key);
    }

    getStateFromCoordinates(lat, lng): Observable<any> {
        return this.http.get<any>(this.googleMapsUrl + 'latlng=' + lat + ',' + lng + '&key=' + environment.google_maps_key);
    }
}
