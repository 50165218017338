import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { College } from '../models/college';
import { ApiManagerService } from './api-manager.service';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CollegesService {
    private collegesUrl = 'Colleges/Get';
    private collegesByStateUrl = 'Colleges/GetCollegesByState';

    private host: string;

    constructor(
        private http: HttpClient,
        private apiSvc: ApiManagerService,
    ) {
        this.host = this.apiSvc.getApiHostBase();
    }

    getColleges(state: string): Observable<College[]> {
        let obs: Observable<CollegesResponse>;
        if (!state) {
            obs = this.http.get<CollegesResponse>(this.host + this.collegesUrl);
        } else {
            obs = this.http.get<CollegesResponse>(this.host + this.collegesByStateUrl + '?state=' + state);
        }

        return obs.pipe(
            map(res => {
                // check validity
                if ((res.success !== undefined && !res.success) || res.data === undefined) {
                    throw (res.message || 'Invalid Response');
                }
                return res.data;
            }),
        );
    }
}

interface CollegesResponse {
    success: boolean;
    message: string;
    data?: College[];
}
