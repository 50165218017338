import { Component, OnInit } from '@angular/core';
import { News } from './news';
import { NewsService } from '../../services/news.service';

@Component({
  selector: 'news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {
	news: News[];

	constructor(private newsService: NewsService) { }

	ngOnInit() {
		this.getNews();
	}

	getNews(): void {
		this.newsService.getNews()
		  .subscribe(news => this.news = news);
	}
}
