import { Component, OnInit, Input, Output, EventEmitter, ViewChild, HostListener, AfterViewInit } from '@angular/core';
import { PartnerLead } from '../../models/interested-partner';
import { LearnMoreService } from './../../services/learnmore.service';
import { GoogleAnalyticsEventTrackingService } from '../../services/google-analytics-event-tracking.service';

@Component({
	selector: 'partner-school',
	templateUrl: './partner-school.component.html',
	styleUrls: ['./partner-school.component.scss'],
	providers: [GoogleAnalyticsEventTrackingService]
})
export class PartnerSchoolComponent implements OnInit, AfterViewInit {
	@Input() showInterested: boolean;
	@Output() setInterested = new EventEmitter<boolean>();
	partnerModel: PartnerLead = new PartnerLead();
	submitted: boolean = false;
	public success: boolean = false;
	error: boolean = false;
	loading: boolean = false;
	scroll: boolean;
	@ViewChild('s', { static: false }) s;

	constructor(
		private learnMoreService: LearnMoreService,
		private gaEventTrackingSvc: GoogleAnalyticsEventTrackingService
	) { }

	ngOnInit() {
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.resizeHero();
		}, 1);
	}

	@HostListener('window:resize') onResize() {
		this.resizeHero();
	}

	interested(agreed: boolean) {
		this.setInterested.emit(agreed);
		this.success = false;
		this.partnerModel = new PartnerLead();
		setTimeout(() => {
			this.s.submitted = false;
		}, 1);
	}

	onInterested() {
		this.learnMoreService.addLead(this.partnerModel).then(data => {
			this.success = true;
			this.loading = false;
		},
			error => {
				this.error = true;
				this.loading = false;
			});

		this._trackEvent('partnerSchool', 'partnerPopup', 'learnMore', 'click');
	}

	resizeHero() {
		//determine the height of the window
		if (window.innerHeight < 761) {
			this.scroll = true;
		} else {
			this.scroll = false;
		}
	}

	public _trackEvent(component: string, destination: string, element: string, category: string) {
		this.gaEventTrackingSvc._trackEvent(component, destination, element, category);
	}
}
