import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'phone' })
export class PhonePipe implements PipeTransform {
    transform(val, args) {
        let city, number;

        city = val.slice(0, 3);
        number = val.slice(3);
        
        return "(" + city + ") " + number.slice(0, 3) + '-' + number.slice(3);
    }
}