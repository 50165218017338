import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ApiManagerService } from './api-manager.service';
import { DealerUserRole } from '../models/dealer-user-role';
import { UserInfo } from '../models/user-info';
import { DealerUserResponseData } from '../models/dealer-user-response-data';
import { map, catchError } from 'rxjs/operators';
import { TokenUserInfo } from '../models/token-user-info';
import { TokenUserInfoResponseData } from '../models/token-user-info-response-data';

@Injectable({
    providedIn: 'root'
})
export class TokenUserDataProviderService {
    private host: string;
    private getTokenUserUrl = 'users/gettokenuser/?token=';


    constructor(
        private http: HttpClient,
        private apiSvc: ApiManagerService,
    ) {
        this.host = this.apiSvc.getApiHostBase();
    }

    public getTokenUserInfo(token: string): Observable<TokenUserInfo> {
        const getUrl = this.host + this.getTokenUserUrl + token;
        return this.http.get<TokenUserInfoResponse>(getUrl).pipe(
            map(res => {
                // check validity
                if ((res.success !== undefined && !res.success) || res.data === undefined) {
                    this.denyAccess();
                    throw (res.message || 'Invalid Response');
                }
                return this.mapTokenUserInfo(res.data);
            }),
            catchError(err => {
                this.denyAccess();
                return throwError(err);
            }),
        );
    }

    public denyAccess() {
        localStorage.removeItem('token');
        localStorage.removeItem('ssouser');
        const nnaNetURL: string = this.apiSvc.getNnaNetUrl();
        window.location.href = nnaNetURL;
    }

    private mapTokenUserInfo(rawData: TokenUserInfoResponseData): TokenUserInfo {
        return {
            id: rawData.id,
            userId: rawData.loginId,
            firstName: rawData.firstName,
            lastName: rawData.lastName,
            userType: rawData.userType,
            ssoUserType: rawData.ssoUsertype,
            primaryDealershipCode: rawData.primaryDealershipCode,
            allowedDealerships: rawData.allowedDealerships,
            dealerUserInfo: this.mapDealerUserInfo(rawData.dealerUserInfo),
            postalCode: rawData.postalCode,
            primaryRole: rawData.primaryRole
        };
    }

    private mapDealerUserInfo(rawData: DealerUserResponseData[]): UserInfo[] {
        if (rawData != null) {
            return rawData.map(rawDealerUserData => {
                return {
                    id: rawDealerUserData.id,
                    userId: rawDealerUserData.loginId,
                    firstName: rawDealerUserData.firstName,
                    lastName: rawDealerUserData.lastName,
                    position: rawDealerUserData.title,
                    email: rawDealerUserData.email,
                    dealerCode: rawDealerUserData.dealerCode,
                    brandId: rawDealerUserData.brandId,
                    role: this.determineUserRole(rawDealerUserData),
                };
            });
        } else {
            return [];
        }
    }

    private determineUserRole(userInfo: DealerUserResponseData): DealerUserRole {
        if (userInfo.isApprover) {
            return DealerUserRole.APPROVER;
        }
        if (userInfo.position === 300||userInfo.position === 360) {
            return DealerUserRole.DEALER;
        }
        return DealerUserRole.READ_ONLY;
    }

 
}

interface TokenUserInfoResponse {
    success: boolean;
    message: string;
    data: TokenUserInfoResponseData;
}



