import { Component, OnInit } from '@angular/core';
import { College } from '../../models/college';
import { CollegesService } from '../../services/colleges.service';

@Component({
  selector: 'college-map',
  templateUrl: './college-map.component.html',
  styleUrls: ['./college-map.component.scss']
})
export class CollegeMapComponent implements OnInit {
	title: string = 'College Locator Map';
	lat: number = 39;
	lng: number = -95;
	zoom: number = 4;
	colleges: College[];
	
	constructor(private collegesService: CollegesService) { }

	ngOnInit() {
		this.getColleges("ALL");
	}

	getColleges(state: string): void {
		this.collegesService.getColleges(state === "ALL" ? '' : state)
		  .subscribe(colleges => this.colleges = colleges);
	}
}
