import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { DealershipsDataProviderService } from '../../services/dealerships.data-provider.service';
import { GoogleAnalyticsEventTrackingService } from '../../services/google-analytics-event-tracking.service';

@Component({
    selector: 'home-retailers',
    templateUrl: './home-retailers.component.html',
    styleUrls: ['./home-retailers.component.scss'],
    providers: [GoogleAnalyticsEventTrackingService]
})
export class HomeRetailersComponent implements OnInit {
    model: any = {};
    brands = [
        { id: 1, logo: '/assets/images/nissan_logo-2020.png' },
        { id: 2, logo: '/assets/images/retailer_infiniti.png' }
    ];
    placeholder = 'Enter a Zip Code to find a location near you';
    selectedBrand = this.brands[0].id;

    constructor(
        public router: Router,
        private dealersService: DealershipsDataProviderService,
        private gaEventTrackingSvc: GoogleAnalyticsEventTrackingService
    ) { }

    ngOnInit() {
        if (window.innerWidth < 577) {
            this.placeholder = 'Enter a Zip Code';
        }
    }

    @HostListener('window:resize') onResize() {
        if (window.innerWidth < 577) {
            this.placeholder = 'Enter a Zip Code';
        } else {
            this.placeholder = 'Enter a Zip Code to find a location near you';
        }
    }

    onSubmit() {
        this.dealersService.setBrand(this.selectedBrand);
        this.dealersService.setZip(this.model.zipcode);
        this.router.navigate(['/dealers']);
        this._trackEvent('retailer', 'retailerLocator', this.model.zipcode + '_' + this.selectedBrand, 'click');
    }

    public _trackEvent(component: string, destination: string, element: string, category: string) {
        this.gaEventTrackingSvc._trackEvent(component, destination, element, category);
    }
}
