import { BrowserModule, Title } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpParams } from '@angular/common/http';
import { AppComponent } from './app.component';
import { RoutingConfig } from './app-routing.module';
import { AgmCoreModule } from '@agm/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { TestimonialsComponent } from './components/testimonials/testimonials.component';
import { BenefitsComponent } from './components/benefits/benefits.component';
import { CollegesComponent } from './components/colleges/colleges.component';
import { EnrollmentHowTosComponent } from './components/enrollmenthowtos/enrollmenthowtos.component';
import { FaqsComponent } from './components/faqs/faqs.component';
import { FeaturesComponent } from './components/features/features.component';
import { JobsComponent } from './components/jobs/jobs.component';
import { StatsComponent } from './components/stats/stats.component';
import { NewsComponent } from './components/news/news.component';
import { CollegeMapComponent } from './components/college-map/college-map.component';
import { CollegeLocatorComponent } from './components/college-locator/college-locator.component';
import { DealerLocatorComponent } from './components/dealer-locator/dealer-locator.component';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { UiModule } from './ui/ui.module';
import { HomeComponent } from './components/home/home.component';
import { HomeHeroComponent } from './components/home-hero/home-hero.component';
import { HomeHeroCTAComponent } from './components/home-hero-cta/home-hero-cta.component';
import { HomePromosComponent } from './components/home-promos/home-promos.component';
import { HomePromos2Component } from './components/home-promos2/home-promos2.component';
import { HomeProgramComponent } from './components/home-program/home-program.component';
import { HomeRetailersComponent } from './components/home-retailers/home-retailers.component';
import { HomeInformationComponent } from './components/home-information/home-information.component';
import { UsMapComponent } from './components/us-map/us-map.component';
import { GoogleAnalyticsEventTrackingService } from './services/google-analytics-event-tracking.service';
import { ProgramComponent } from './components/program/program.component';
import { GetStartedComponent } from './components/getstarted/getstarted.component';
import { PartnerSchoolComponent } from './components/partner-school/partner-school.component';
import { Error404PageComponent } from './components/error-404-page/error-404-page.component';
import { HomeGetStartedComponent } from './components/home-get-started/home-get-started.component';

import { CoreModule } from './core/core.module';
import { AppConfigService } from './services/app-config.service';
import { ApiAuthService } from './services/api-auth.service';
import { AuthInterceptor } from './services/auth-interceptor.service';
import { HomeTestimonialComponent } from './components/home-testimonial/home-testimonial.component';
import { TestimonialVideoComponent } from './components/testimonial-video/testimonial-video.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import { NgIdleModule } from '@ng-idle/core';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { MatDialogModule } from '@angular/material/dialog';
import { LogoutAlertTemplateComponent } from './dealer-portal/logout-alert/logout-alert-template/logout-alert-template.component';
import { ConfigService } from './services/config.service';


const appInitializerFunction = (appConfig: AppConfigService, apiAuthSvc: ApiAuthService) => {
    return async () => {
        return appConfig.loadAppConfig()
            .then(() => apiAuthSvc.loadApiToken(appConfig.config));
    };
};

@NgModule({
    declarations: [
        LogoutAlertTemplateComponent,
        AppComponent,
        TestimonialsComponent,
        BenefitsComponent,
        CollegesComponent,
        EnrollmentHowTosComponent,
        FaqsComponent,
        FeaturesComponent,
        JobsComponent,
        StatsComponent,
        NewsComponent,
        CollegeLocatorComponent,
        DealerLocatorComponent,
        CollegeMapComponent,
        ContactFormComponent,
        HomeComponent,
        HomeHeroComponent,
        HomeHeroCTAComponent,
        HomePromosComponent,
        HomePromos2Component,
        HomeProgramComponent,
        HomeRetailersComponent,
        HomeInformationComponent,
        GetStartedComponent,
        UsMapComponent,
        ProgramComponent,
        PartnerSchoolComponent,
        Error404PageComponent,
        HomeGetStartedComponent,
        HomeTestimonialComponent,
        TestimonialVideoComponent,
    ],
    entryComponents:[LogoutAlertTemplateComponent],
    imports: [
        MatDialogModule,
        NgIdleModule.forRoot(),
        NgIdleKeepaliveModule.forRoot(),
        RouterModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        NgSelectModule,
        FormsModule,
        ReactiveFormsModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyDakyH2kQkFBxtV2aeORZwVOd456ncmAvc'
        }),
        UiModule,
        CoreModule,
        NgbModule,
        RoutingConfig,
    ],
    providers: [
        AppConfigService,
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFunction,
            multi: true,
            deps: [AppConfigService, ApiAuthService]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        Title,
        GoogleAnalyticsEventTrackingService,
        ConfigService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
