import { UserInfo } from './user-info';

export interface TokenUserInfo {
    id: number;
    userId: string;
    firstName: string;
    lastName: string;
    userType: string;
    ssoUserType: SSOUserType;
    primaryDealershipCode: string;
    allowedDealerships: string;
    dealerUserInfo: UserInfo[];
    postalCode:string;
    primaryRole:string;
}

export enum SSOUserType {
    DEALER = 'D',
    CORPORATE = 'C',
    EXTERNAL = 'E',
}
