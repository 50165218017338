export class Lead {
    constructor() { }
    public fullName = '';
    public firstName = '';
    public lastName = '';
    public city = '';
    public zip = '';
    public email = '';
    public phone = '';
    public occupation = '';
    public sourceId = 0;
    public collegeId = 0;
    public college = '';
    public brandId = 1;
}
