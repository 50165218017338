import { Component, OnInit } from '@angular/core';
import { Feature } from './feature';
import { FeaturesService } from '../../services/features.service';
import { Title } from '@angular/platform-browser';

@Component({
	selector: 'features',
	templateUrl: './features.component.html',
	styleUrls: ['./features.component.scss']
})
export class FeaturesComponent implements OnInit {
	features: Feature[];

	constructor(
		private featuresService: FeaturesService,
		private titleService: Title
	) { }

	ngOnInit() {
		this.getFeatures();
		this.titleService.setTitle("NISSAN NTTA - The Program");
	}

	getFeatures(): void {
		this.featuresService.getFeatures()
			.subscribe(features => this.features = features);
	}
}
