import { Component, OnInit } from '@angular/core';
import { GoogleAnalyticsEventTrackingService } from '../../services/google-analytics-event-tracking.service';
import { CurrentTokenUserService } from '../../services/current-token-user.service';
import { environment } from '../../../environments/environment';
import { ConfigService } from '../../services/config.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    providers: [GoogleAnalyticsEventTrackingService]
})
export class FooterComponent implements OnInit {
    public copyright = new Date().getFullYear();
    version: any;
    constructor(
        private gaEventTrackingSvc: GoogleAnalyticsEventTrackingService,
        private userSvc: CurrentTokenUserService,
        private configService: ConfigService
    ) { }

    ngOnInit() {
        this.configService.getConfig().subscribe((config:any)=>{
            this.version = config.version;
        });
    }

    getIsLoggedIn() {
        const curHref = window.location.href;
        const hideLinks = this.userSvc.isLoggedIn() || curHref.toLocaleLowerCase().includes('/logout');
        return hideLinks;
    }

    public _trackEvent(component: string, destination: string, element: string, category: string) {
        this.gaEventTrackingSvc._trackEvent(component, destination, element, category);
    }
}
