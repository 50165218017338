import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Lead } from '../../models/lead';
import { College } from '../../models/college';
import { LeadsService } from '../../services/leads.service';
import { MailService } from '../../services/mail.service';
import { CollegesService } from '../../services/colleges.service';
import { environment } from '../../../environments/environment';

@Component({
	selector: 'contact-form',
	templateUrl: './contact-form.component.html',
	styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {

	model: Lead = new Lead();
	//college: College;
	submitted = false;

	constructor(
		private leadsService: LeadsService,
		private mailService: MailService,
		private collegesService: CollegesService,
		private route: ActivatedRoute,
	) {
	}

	ngOnInit() {
		/*     	const collegeId = this.route.snapshot.paramMap.get('id');
				this.model.collegeId = parseInt(collegeId) || null;
				this.model.brandId = environment.brandId;
				  this.collegesService.getCollege((this.model.collegeId == null) ? '' : this.model.collegeId)
				  .subscribe(college => { 
						this.college = college[0];
				  });*/
	}

	onSubmit() {
		//let id = this.route.snapshot.paramMap.get('id');
		this.leadsService.addLead(this.model);
		this.mailService.send(this.model);
		this.submitted = true;
	}
}
