import { Component, OnInit, AfterViewInit } from '@angular/core';
import { LeadsService } from '../../services/leads.service';
import { College } from '../../models/college';
import { Source } from '../../models/source';
import { CollegesService } from './../../services/colleges.service';
import { SourcesService } from './../../services/sources.service';
import { Lead } from './../../models/lead';
import { ActivatedRoute } from '@angular/router';
import { GoogleAnalyticsEventTrackingService } from '../../services/google-analytics-event-tracking.service';
import { StatePipe } from '../../core/pipes/state.pipe';

@Component({
    selector: 'home-hero-cta',
    templateUrl: './home-hero-cta.component.html',
    styleUrls: ['./home-hero-cta.component.scss'],
    providers: [GoogleAnalyticsEventTrackingService, StatePipe]
})
export class HomeHeroCTAComponent implements OnInit, AfterViewInit {
    model: Lead;
    public programs: College[];
    public sortedPrograms = new Object;
    public sources: Source[];
    public success = false;
    error = false;
    loading = false;
    share = false;
    private collegeid: number = null;
    selectedCollege: string;

    constructor(
        private leadsService: LeadsService,
        private collegesService: CollegesService,
        private sourcesService: SourcesService,
        private activatedRoute: ActivatedRoute,
        private gaEventTrackingSvc: GoogleAnalyticsEventTrackingService,
        private statePipeTransform: StatePipe
    ) {
        if (this.activatedRoute.snapshot.paramMap.has('collegeid')) {
            this.collegeid = +this.activatedRoute.snapshot.paramMap.get('collegeid');
        }
    }

    ngOnInit() {
        this.collegesService.getColleges('').subscribe(data => {
            this.programs = data;

            if (this.collegeid !== null) {
                const collegeObj = data.find(x => x.collegeId === this.collegeid);
                if (!!collegeObj) {
                    this.selectedCollege = collegeObj.name;
                } else {
                    this.collegeid = null;
                    this.model.collegeId = null;
                }
            }

            // Set the state name so we can sort them properly
            this.programs.map(item => item.stateName = this.transformState(item.state));

            // Group colleges by state
            this.programs = this.groupBy(this.programs, 'stateName');
        });
        this.sourcesService.getSources().subscribe(data => this.sources = data);

        // select the first program
        this.model = new Lead();
        this.model.collegeId = this.collegeid;
        this.model.sourceId = null;
    }

    ngAfterViewInit() {
        setTimeout(() => document.getElementById('fullName').focus(), 10);
    }

    onSubmit() {
        this.loading = true;
        this.leadsService.addLead(this.model).then(data => {
            this.success = true;
            this.loading = false;
        },
            error => {
                this.error = true;
                this.loading = false;
            });

        // Google Analytics
        this._trackEvent('leadGeneration', 'lead', 'Get Enrollment Info', 'click');
    }

    groupBy(xs, key) {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    }

    setSelectedCollege(event) {
        this.selectedCollege = event.target['options'][event.target['options'].selectedIndex].text;
    }

    // Transform the abbreviated state name to the full name
    transformState(stateAbbrev: String) {
        return this.statePipeTransform.transform(stateAbbrev);
    }

    public _trackEvent(component: string, destination: string, element: string, category: string) {
        this.gaEventTrackingSvc._trackEvent(component, destination, element, category);
    }
}
