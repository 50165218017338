import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ShareLead } from '../models/share-lead';
import { ApiManagerService } from './api-manager.service';

@Injectable({
    providedIn: 'root'
})
export class ShareService {
    private shareUrl = 'mail/share';
    private host: string;

    constructor(
        private http: HttpClient,
        private apiSvc: ApiManagerService,
    ) {
        this.host = this.apiSvc.getApiHostBase();
    }

    shareLead(lead: ShareLead): Promise<any> {
        return this.http.post(this.host + this.shareUrl, lead).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorObservable);
    }

    extractData(_res: Response) {
        return 'success';
    }
    handleErrorObservable(error: Response | any) {
        console.error(error.message || error);
        return error.message || error;
    }
}
