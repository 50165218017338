import { Component, OnInit } from '@angular/core';
import { EnrollmentHowTo } from './enrollmenthowto';
import { EnrollmentHowTosService } from '../../services/enrollmenthowtos.service';

@Component({
  selector: 'enrollmenthowtos',
  templateUrl: './enrollmenthowtos.component.html',
  styleUrls: ['./enrollmenthowtos.component.scss']
})
export class EnrollmentHowTosComponent implements OnInit {
	enrollmenthowtos: EnrollmentHowTo[];

	constructor(private enrollmenthowtosService: EnrollmentHowTosService) { }

	ngOnInit() {
		this.getEnrollmentHowTos();
	}

	getEnrollmentHowTos(): void {
		this.enrollmenthowtosService.getEnrollmentHowTos()
		  .subscribe(enrollmenthowtos => this.enrollmenthowtos = enrollmenthowtos);
	}
}
