import { State } from './state';

export const STATES: State[] = [
  { id: 0, name: 'ALL' },
  { id: 1, name: 'AL' },
  { id: 2, name: 'AZ' },
  { id: 3, name: 'CO' },
  { id: 4, name: 'DE' },
  { id: 5, name: 'MN' },
  { id: 6, name: 'MO' },
  { id: 7, name: 'TX' },
  { id: 8, name: 'WA' },
  { id: 9, name: 'WI' },
  { id: 10, name: 'WY' }
];