import { Component, OnInit } from '@angular/core';
import { Job } from './job';
import { JobsService } from '../../services/jobs.service';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'jobs',
    templateUrl: './jobs.component.html',
    styleUrls: ['./jobs.component.scss']
})
export class JobsComponent implements OnInit {
    jobs: Job[];

    constructor(
        private jobsService: JobsService,
        private titleService: Title
    ) { }

    ngOnInit() {
        this.getJobs();
        this.titleService.setTitle('Careers | Nissan Technician Training Academy');
    }

    getJobs(): void {
        this.jobsService.getJobs()
            .subscribe(jobs => this.jobs = jobs);
    }
}
