import { Component, OnInit } from '@angular/core';
import { UsMapService } from '../../services/us-map.service';
import { State } from '../../models/state';
import { College } from './../../models/college';
import { CollegesService } from './../../services/colleges.service';
import { GoogleAnalyticsEventTrackingService } from '../../services/google-analytics-event-tracking.service';
import { StatePipe } from '../../core/pipes/state.pipe';

@Component({
    selector: 'home-program',
    templateUrl: './home-program.component.html',
    styleUrls: ['./home-program.component.scss'],
    providers: [GoogleAnalyticsEventTrackingService, StatePipe]
})
export class HomeProgramComponent implements OnInit {
    public states: State[] = [];
    public programs: {};
    allSchools: College[];
    selectedState: string = 'AZ';
    selectedTooltip: string = 'AZ0';
    public result: College;
    public lookupResult = [];
    showInterested: boolean = false;

    constructor(
        private usMapService: UsMapService,
        private collegeService: CollegesService,
        private gaEventTrackingSvc: GoogleAnalyticsEventTrackingService,
        private statePipeTransform: StatePipe
    ) { }

    ngOnInit() {
        this.collegeService.getColleges('').subscribe(data => {
            // sort alpha to make sure all indices match
            this.allSchools = data.sort((collegeA, collegeB) => {
                const nameA = collegeA.name.toLocaleLowerCase();
                const nameB = collegeB.name.toLocaleLowerCase();
                return nameA < nameB ? -1 : 1;
            });
            const colleges = this.allSchools;

            // Set the state name so we can sort them properly
            colleges.map(item => item.stateName = this.transformState(item.state));

            // Group colleges by state
            this.programs = this.groupBy(colleges, 'stateName');

            // Select the first school
            this._selectSchool();
        });
        this.usMapService.getUsMapCoordinates().then(data => this.states = data);
    }

    usMapClick($event) {
        this.selectedState = $event.state;
        this.selectedTooltip = $event.location;
        this._selectSchool();
    }

    _selectState(state) {
        this.selectedState = /([A-Z]+)[0-9]/.exec(state)[1];
        this._selectSchool();
    }

    _selectSchool() {
        // find the school for this state
        const index = /[A-Z]+([0-9])/.exec(this.selectedTooltip)[1];
        const schoolsForSelectedState = this.allSchools.filter(s => s.state == this.selectedState);
        this.result = schoolsForSelectedState[index];
    }

    onSetInterested(agreed: boolean) {
        this.showInterested = agreed;
    }

    groupBy(xs: College[], key) {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    }

    // Transform the abbreviated state name to the full name
    transformState(stateAbbrev: String) {
        return this.statePipeTransform.transform(stateAbbrev);
    }

    public _trackEvent(college) {
        this.gaEventTrackingSvc._trackEvent('home-program', 'getstarted', college, 'navigation');
    }
}