import { Component, OnInit, AfterViewInit, HostListener, ViewChild, Input } from '@angular/core';
import { ShareLead } from '../../models/share-lead';
import { ShareService } from './../../services/share.service';
import { GoogleAnalyticsEventTrackingService } from '../../services/google-analytics-event-tracking.service';
import { Benefit } from './../../models/benefit';
import { Observable } from 'rxjs';

@Component({
	selector: 'home-hero',
	templateUrl: './home-hero.component.html',
	styleUrls: ['./home-hero.component.scss'],
	providers: [GoogleAnalyticsEventTrackingService]
})
export class HomeHeroComponent implements OnInit, AfterViewInit {
	@Input() content: Benefit;
	shareModel: ShareLead = new ShareLead();
	public success: boolean = false;
	share: boolean = false;
	error: boolean = false;
	loading: boolean = false;
	submitted: boolean = false;
	scroll: boolean;
	bodyError: boolean = false;
	@ViewChild('s', { static: false }) s;

	constructor(
		private shareService: ShareService,
		private gaEventTrackingSvc: GoogleAnalyticsEventTrackingService
	) { }

	@HostListener('window:resize') onResize() {
		this.resizeHero();
	}

	ngOnInit() {
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.resizeHero();
		}, 1);
	}

	resizeHero() {
		/*const nav = document.getElementsByTagName('nav')[0];
		const hero = document.getElementsByTagName('home-hero')[0];
		(<HTMLElement>hero).style.marginTop = (<HTMLElement>nav).offsetHeight * -1 + 'px';
		(<HTMLElement>hero).style.paddingTop = (<HTMLElement>nav).offsetHeight + 74 + 'px';*/

		//mobile styling
		/*if (window.innerWidth < 321) {
			(<HTMLElement>hero).style.marginTop = '0';
			(<HTMLElement>hero).style.paddingTop = '130px';
		}
		else if (window.innerWidth < 576) {
			(<HTMLElement>hero).style.marginTop = '0';
			(<HTMLElement>hero).style.paddingTop = '170px';
		}*/

		//determine the height of the window
		if (window.innerHeight < 672) {
			this.scroll = true;
		} else {
			this.scroll = false;
		}
	}

	onShare() {
		if (this.shareModel.body.length < 2 || this.shareModel.body.length > 500) {
			this.bodyError = true;
		} else {
			this.bodyError = false;
		}

		if (!this.bodyError) {
			this.shareService.shareLead(this.shareModel).then(data => {
				this.success = true;
				this.loading = false;
				this.submitted = true;
			},
				error => {
					this.error = true;
					this.loading = false;
				});

			//Google Analytics
			this._trackEvent('shareEmail', 'sendemail', 'click', 'sharePopup');
		}
	}

	resetForm() {
		this.share = true;
		this.submitted = false;
		this.success = false;

		setTimeout(() => {
			this.s.submitted = false;
		}, 1);

		this.shareModel = new ShareLead()
		this.shareModel.body = 'Hi!\n\nI thought you might be interested in checking out this website for NISSAN Technician Training Academy (NTTA). NTTA prepares you for a career as a factory-trained technician at NISSAN or INFINITI dealerships nationwide. NTTA does not collect your email address and will not reach out to you directly.'
	}

	public _trackEvent(component: string, destination: string, element: string, category: string) {
		this.gaEventTrackingSvc._trackEvent(component, destination, element, category);
	}

}
