import { Component, OnInit, Input, Output, EventEmitter, HostListener, OnChanges } from '@angular/core';
import { UsMapService } from '../../services/us-map.service';
import { GoogleAnalyticsEventTrackingService } from '../../services/google-analytics-event-tracking.service';
import { College } from './../../models/college';

@Component({
    selector: 'us-map',
    templateUrl: './us-map.component.html',
    styleUrls: ['./us-map.component.scss'],
    providers: [GoogleAnalyticsEventTrackingService]
})
export class UsMapComponent implements OnInit, OnChanges {
    coordinates: any[];
    activeState: string;
    fillColor: string = "#C7C7C7";
    fillStateColor: string = "#C7C7C7";
    strokeColor: string = "#FFF";
    @Input() allSchools: College[];
    @Input() selectedState: string;
    @Input() selectedTooltip: string;
    @Output('onMapClick') click = new EventEmitter();

    constructor(
        private usMapService: UsMapService,
        private gaEventTrackingSvc: GoogleAnalyticsEventTrackingService
    ) { }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.setTooltips();
    }

    ngOnInit() {
        this.usMapService.getUsMapCoordinates().then(data => {
            this.coordinates = data;
        });
    }

    ngOnChanges(changes) {
        if (changes["allSchools"] && this.allSchools) {
            //match up the coordinates with the schools
            let i = this.coordinates.length;
            while (i--) {
                let stateSchools = this.allSchools.filter(s => s.state == this.coordinates[i].id);

                if (stateSchools.length) {
                    //we have the coordinates of the schools
                    //attach them to the state
                    if (this.coordinates[i].loc == undefined) {
                        this.coordinates[i].loc = [];
                    }

                    for (let j = 0; j < stateSchools.length; j++) {
                        //don't add the same college twice
                        if (!this.coordinates[i].loc.find(c => c.collegeId === stateSchools[j].collegeId)) {

                            this.coordinates[i].loc.push({
                                city: stateSchools[j].city + ", " + stateSchools[j].state,
                                tooltip: stateSchools[j].toolTip,
                                x: stateSchools[j].xAxis,
                                y: stateSchools[j].yAxis,
                                collegeId: stateSchools[j].collegeId
                            });
                        }
                    }
                }
            }

            //position the tooltips after coordinates are created
            setTimeout(() => {
                this.setTooltips();
            }, 10);
        }
    }

    onUsMapClick(state, location) {
        let $event = { 'state': state, 'location': location }
        this.click.emit($event);
        this._trackEvent('collegeMap', location, 'dot', 'click');
    }

    setTooltips() {
        const pois = document.querySelectorAll("circle");
        for (let i = 0; i < pois.length; i++) {
            this.positionTooltip(pois[i])
        }
    }

    positionTooltip(elem) {
        const mapWrapper = document.getElementById('mapWrapper');
        const stateid = elem.getAttribute('id');
        const pois = document.querySelectorAll('li[locid="' + stateid + '"]');
        for (let i = 0; i < pois.length; i++) {
            if ((<HTMLElement>pois[i]).classList.contains('right')) {
                (<HTMLElement>pois[i]).style.left = elem.getBoundingClientRect().left - mapWrapper.getBoundingClientRect().left + 23.5 + 'px';
                (<HTMLElement>pois[i]).style.top = elem.getBoundingClientRect().top - mapWrapper.getBoundingClientRect().top - 25 + 'px';

            } else if ((<HTMLElement>pois[i]).classList.contains('bottom')) {
                (<HTMLElement>pois[i]).style.left = elem.getBoundingClientRect().left - mapWrapper.getBoundingClientRect().left - (<HTMLElement>pois[i]).offsetWidth - 2.5 + 'px';
                (<HTMLElement>pois[i]).style.top = elem.getBoundingClientRect().top - mapWrapper.getBoundingClientRect().top + 25.5 + 'px';

            } else if ((<HTMLElement>pois[i]).classList.contains('top')) {
                (<HTMLElement>pois[i]).style.left = elem.getBoundingClientRect().left - mapWrapper.getBoundingClientRect().left - ((<HTMLElement>pois[i]).offsetWidth * .2) - 15 + 'px';
                (<HTMLElement>pois[i]).style.top = elem.getBoundingClientRect().top - mapWrapper.getBoundingClientRect().top - 72 + 'px';

            } else if ((<HTMLElement>pois[i]).classList.contains('left')) {
                (<HTMLElement>pois[i]).style.left = elem.getBoundingClientRect().left - mapWrapper.getBoundingClientRect().left - (<HTMLElement>pois[i]).offsetWidth - 50 + 'px';
                (<HTMLElement>pois[i]).style.top = elem.getBoundingClientRect().top - mapWrapper.getBoundingClientRect().top - 25.3 + 'px';

            }
        }
    }

    public _trackEvent(component: string, destination: string, element: string, category: string) {
        this.gaEventTrackingSvc._trackEvent(component, destination, element, category);
    }
}