import { Injectable } from '@angular/core';
import { Observable, } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Faq, FaqResource } from '../components/faqs/faq';
import { ApiManagerService } from './api-manager.service';

@Injectable({
    providedIn: 'root'
})
export class FaqsService {
  private faqsUrl = 'faqs';
  private faqsresourceUrl = '/getfaqresources';
  
    private host: string;

    constructor(
        private http: HttpClient,
        private apiSvc: ApiManagerService,
    ) {
        this.host = this.apiSvc.getApiHostBase();
    }

    getFaqs(): Observable<Faq[]> {
    return this.http.get<Faq[]>(this.host + this.faqsUrl + '/get'); //Existing method added get action 
  }

    getFaqResource(programId: number): Observable<Faq[]> {
      return this.http.get<Faq[]>(this.host + this.faqsUrl + '/getfaqs' +'?programid=' + programId);
  }

  getFaqResourceForDownload(): Observable<FaqResource[]> {
    var a = this.http.get<FaqResource[]>(this.host + this.faqsUrl + this.faqsresourceUrl);
    return this.http.get<FaqResource[]>(this.host + this.faqsUrl + this.faqsresourceUrl);
  }
}
