import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Dealer } from '../components/dealer-locator/dealer';
import { ApiManagerService } from './api-manager.service';
import { BRAND_ID } from '../shared/brand-id.enum';

@Injectable({
    providedIn: 'root'
})
export class DealershipsDataProviderService {
    private dealersUrl = 'dealerships/gettop25dealerships';
    private dealershipsByZipUrl = 'dealerships/GetTop20DealershipsByBrandAndZip';
    private filteredDealershipsByDealerCodeUrl = 'dealerships/GetFilteredDealerships?filteredDealers=';
    private host: string;
    private _brand = 1;
    private _zip = '';
    private getDealersByDealerCodeUrl="dealers/GetDealershipInfo?dealershipCode=";
    private getDealershipSalesLocationUrl = 'DealerShips/GetDealershipLocationInfo?dealershipCode=';

    constructor(
        private http: HttpClient,
        private apiSvc: ApiManagerService,
    ) {
        this.host = this.apiSvc.getApiHostBase();
    }

    getDealers(): Observable<Dealer[]> {
        return this.http.get<Dealer[] | DealersResponse>(this.host + this.dealersUrl).pipe(
            map(this.validateDataFutureProof),
        );
    }

    getDealersByZip(): Observable<Dealer[]> {
        const getUrl = this.host + this.dealershipsByZipUrl + '?brandId=' + this._brand + '&zip=' + this._zip;
        return this.http.get<DealershipsResponse>(getUrl).pipe(
            map(res => {
                // check validity
                if ((res.success !== undefined && !res.success) || res.data === undefined) {
                    throw (res.message || 'Invalid Response');
                }
                return res.data.map(d => this.mapDealershipInfo(d));
            }),
        );
    }

    getDealersByDealerCode(dealerCodes: string): Observable<Dealer[]> {
        return this.http.get<any>(this.host + this.getDealersByDealerCodeUrl + dealerCodes).pipe(
            map(res => {
                // check validity
                if ((res.success !== undefined && !res.success) || res.data === undefined) {
                    throw (res.message || 'Invalid Response');
                }
                const dataArray = new Array(res.data);
                return dataArray.map(d => this.mapDealershipInfo(d));
            }),
        );
    }

    getFilteredDealerships(dealerCodes: string): Observable<Dealer[]> {
        return this.http.get<DealershipsResponse>(this.host + this.filteredDealershipsByDealerCodeUrl + dealerCodes).pipe(
            map(res => {
                // check validity
                if ((res.success !== undefined && !res.success) || res.data === undefined) {
                    throw (res.message || 'Invalid Response');
                }
                return res.data.map(d => this.mapDealershipInfo(d));
            }),
        );
    }

    private validateDataFutureProof(response: Dealer[] | DealersResponse): Dealer[] {
        // check validity
        if ((response as DealersResponse).success !== undefined 
                && (!(response as DealersResponse).success || (response as DealersResponse).data === undefined))
        {
            throw ((response as DealersResponse).message || 'Invalid Response');
        }
        // future-proofing the data
        if (!!(response as DealersResponse).data) {
            response = (response as DealersResponse).data;
        }

        return (response as Dealer[]);
    }

    setBrand(id: number) {
        this._brand = id;
    }

    getBrand() {
        return this._brand;
    }

    setZip(zip: string) {
        this._zip = zip;
    }

    getZip() {
        return this._zip;
    }

    mapDealershipInfo(rawDealerInfo: DealershipResponseData): Dealer {
        const mappedData: Dealer = {
            dealerId: rawDealerInfo.id,
            dealerCode: rawDealerInfo.dealerCode,
            name: rawDealerInfo.dbaName || rawDealerInfo.dealerName,
            address1: rawDealerInfo.address,
            address2: null,
            city: rawDealerInfo.city,
            state: rawDealerInfo.state,
            zip: rawDealerInfo.zip,
            phone: rawDealerInfo.phoneNumber,
            lat: rawDealerInfo.lat,
            lng: rawDealerInfo.lng,
            distance: null,
            brandId: rawDealerInfo.brandId,
        }
        return mappedData;
    }

    public getDealerSalesLocationDetails(primaryDealershipCode){
        const getUrl = this.host + this.getDealershipSalesLocationUrl+primaryDealershipCode;
        return this.http.get<DealerSalesResponse>(getUrl).pipe(
            map(res => {
                // check validity
                if ((res.success !== undefined && !res.success) || res.data === undefined) {
                    throw (res.message || 'Invalid Response');
                }
                return res.data;
            }),
            catchError(err => {
                return throwError(err);
            }),
        );
    }
}

interface DealersResponse {
    success: boolean;
    message: string;
    data?: Dealer[];
}
interface DealershipsResponse {
    success: boolean;
    message: string;
    data?: DealershipResponseData[];
}

interface DealershipResponseData {
    id: number;
    dealerCode: string;
    dealerName: string;
    brandId: BRAND_ID;
    dbaName: string;
    phoneNumber: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    lat: number;
    lng: number;
}

interface DealerSalesResponse {
    success: boolean;
    message: string;
    data: DealerSalesLocation;
}

export interface DealerSalesLocation {
    dealerShipCode: string;
    area: string;
    region: string;
    district: string;
    channelCode: number;
}
