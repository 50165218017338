import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Lead } from '../models/lead';
import { ApiManagerService } from './api-manager.service';

@Injectable({
    providedIn: 'root'
})
export class MailService {
    private mailUrl = 'mail';
    private host: string;

    constructor(
        private http: HttpClient,
        private apiSvc: ApiManagerService,
    ) {
        this.host = this.apiSvc.getApiHostBase();
    }

    send(lead: Lead): void {
        this.http.post(this.host + this.mailUrl, lead)
            .subscribe(
                data => {
                    // todo: emit an event; confirm success; clear form
                },
                error => {
                    // todo: handle error
                    console.log('Lead Email POST Error', error);
                }
            );
    }
}
