import { Component, OnInit, ViewChild } from '@angular/core';
import { College } from '../../models/college';
import { CollegesService } from '../../services/colleges.service';
import { Title } from '@angular/platform-browser';
import { Meta } from '@angular/platform-browser';
import { GoogleAnalyticsEventTrackingService } from '../../services/google-analytics-event-tracking.service';
import { StatePipe } from '../../core/pipes/state.pipe';

@Component({
    selector: 'college-locator',
    templateUrl: './college-locator.component.html',
    styleUrls: ['./college-locator.component.scss'],
    providers: [GoogleAnalyticsEventTrackingService, StatePipe]
})
export class CollegeLocatorComponent implements OnInit {
    colleges: College[];
    showInterested = false;

    @ViewChild('AgmMap', { static: false }) private agmMap: any;

    constructor(
        private collegesService: CollegesService,
        private titleService: Title,
        private meta: Meta,
        private gaEventTrackingSvc: GoogleAnalyticsEventTrackingService,
        private statePipeTransform: StatePipe
    ) {
        this.meta.updateTag({
            name: 'description',
            // tslint:disable-next-line: max-line-length
            content: 'Select one of our 22 program locations across the country to take the first step into your future with NISSAN or INFINITI.',
        });
    }

    ngOnInit() {
        this.collegesService.getColleges('').subscribe(data => {

            // Set the state name so we can sort them properly
            data.map(item => item.stateName = this.transformState(item.state));

            // Sort the schools by the state name then school name
            this.colleges = data.sort(function (a: College, b: College) {
                const stateA = a.stateName;
                const stateB = b.stateName;
                const schoolA = a.name;
                const schoolB = b.name;
                return (stateA < stateB)
                    ? -1
                    : (stateA > stateB)
                        ? 1
                        : (stateA == stateB)
                            ? (schoolA < schoolB)
                                ? -1
                                : (schoolA > schoolB)
                                    ? 1
                                    : 0
                            : 0;
            })
        });
        this.titleService.setTitle('Locations | Nissan Technician Training Academy');
    }

    onSetInterested(agreed: boolean) {
        this.showInterested = agreed;
    }

    // Transform the abbreviated state name to the full name
    transformState(stateAbbrev: String) {
        return this.statePipeTransform.transform(stateAbbrev);
    }

    public _trackEvent(component: string, destination: string, element: string, category: string) {
        this.gaEventTrackingSvc._trackEvent(component, destination, element, category);
    }
}