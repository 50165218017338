import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Stat } from '../models/stat';
import { environment } from '../../environments/environment';
import { ApiManagerService } from './api-manager.service';

@Injectable({
    providedIn: 'root'
})
export class StatsService {
    private statsUrl = 'stats';
    private host: string;

    constructor(
        private http: HttpClient,
        private apiSvc: ApiManagerService,
    ) {
        this.host = this.apiSvc.getApiHostBase();
    }

    getStats(): Observable<Stat[]> {
        return this.http.get<Stat[]>(this.host + this.statsUrl + '/' + environment.brandId);
    }
}
