import { Component, OnInit } from '@angular/core';
import { College } from '../../models/college';
import { State } from './state';
import { STATES } from './state-list';
import { CollegesService } from '../../services/colleges.service';

@Component({
  selector: 'colleges',
  templateUrl: './colleges.component.html',
  styleUrls: ['./colleges.component.scss']
})
export class CollegesComponent implements OnInit {
	colleges: College[];
	// todo: selector does not belong to this component; take it out
	states: State[] = STATES;
	selectedState: string = "ALL";

	constructor(private collegesService: CollegesService) { }

	ngOnInit() {
		this.getColleges("ALL");
	}

	getColleges(state: string): void {
		this.collegesService.getColleges(state === "ALL" ? '' : state)
		  .subscribe(colleges => this.colleges = colleges);
	}

	onCollegeSelected(): void {
		this.getColleges(this.selectedState);
	}
}
