import { Component, OnInit } from '@angular/core';
import { TokenUserDataProviderService } from '../../services/token-user.data-provider.service';
import { CurrentTokenUserService } from '../../services/current-token-user.service';
import { SSOUserType } from '../../../app/models/token-user-info';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
    userFullName = '';    // use this field to control the Dealer Portal greeting in the header

    constructor(
        private userSvc: CurrentTokenUserService,
        private userDataSvc: TokenUserDataProviderService,
    ) {
    }

    ngOnInit() {
        this.userSvc.getCurrentTokenUser().subscribe({
            next: userInfo => {
                if(userInfo){
                if(userInfo.ssoUserType !== SSOUserType.DEALER){
                if (!!userInfo) {
                    this.userFullName = `${userInfo.firstName} ${userInfo.lastName}`;
                }
            }
                else{
                    if(userInfo.dealerUserInfo&&userInfo.dealerUserInfo.length>0){
                        this.userFullName = `${userInfo.dealerUserInfo[0].firstName} ${userInfo.dealerUserInfo[0].lastName}`
                        }
                        else{
                          if (!!userInfo) {
                            this.userFullName = `${userInfo.firstName} ${userInfo.lastName}`;
                          }
                        }
                }
            }
            },
            error: err => {
                console.error(err);
                this.userDataSvc.denyAccess();
            }
        });
    }
}
