import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { googleAnalyticsEvent } from '../../assets/script';

@Injectable()
export class GoogleAnalyticsEventTrackingService {

    constructor(
        public router: Router
    ) { }

    public _trackEvent(parentcomponent?, page?, element?, selectedCategory?,parameter1?,parameter2?) {
        /*console.log('Track Event');
        console.log('component: ' + component);
        console.log('parent component: ' + parentcomponent);
        console.log('article alias: ' + page);
        console.log('element: ' + element);*/

        let currenturl = decodeURI(this.router.url);
        switch (currenturl) {
            case '/':
                currenturl = 'homepage';
        }

        //console.log('current url: ' + currenturl);

        let category = 'navigation';
        if (selectedCategory != null) {
            category = selectedCategory;
        }


        let action = 'to-' + page + '_from-' + currenturl;

        if (parentcomponent != null) {
            action += '>' + parentcomponent;
        }
        // if(parameter){
        //     action +='>' + parameter;
        // }

        if (element == null) {
            element = '';
        }
        if(parameter1 == null){
            parameter1='';
        }
        if(parameter2 == null){
            parameter2='';
        }

        /*console.log('-----------');
        console.log('[CATEGORY]: ' + category);
        console.log('[ACTION]: ' + action);
        console.log('[LABEL]: ' + element);
        console.log('-----------');*/

        googleAnalyticsEvent(category, action, element,parameter1,parameter2);
    }
    public _trackEventGA4Event(parentcomponent?, page?, element?, selectedCategory?,parameter1?,parameter2?) {
        let action = '';
        if(parentcomponent)
        {
            action+=parentcomponent+"/";
        }
        if(page)
        {
            action+=page+"/";
        }
        if(element)
        {
            action+=element+"/";
        }
        if(selectedCategory){
            action+=selectedCategory+"/";
        }
        else{
            action+="0"+"/";
        }
        if(parameter1){
            action+=parameter1+"/";
        }
        else{
            action+="0"+"/";
        }
        if(parameter2){
            action+=parameter2+"/";
        }

        if (element == null) {
            element = '';
        }
        if(parameter1 == null){
            parameter1='';
        }
        if(parameter2 == null){
            parameter2='';
        }
        let category = 'navigation';
        if (selectedCategory != null) {
            category = selectedCategory;
        }

        googleAnalyticsEvent(category, action, element,parameter1,parameter2);
        
    }
}