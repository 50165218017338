import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Meta } from '@angular/platform-browser';
import { RequirementsService } from './../../services/requirements.service';
import { Requirements } from './../../models/requirements';

@Component({
	selector: 'getstarted',
	templateUrl: './getstarted.component.html',
	styleUrls: ['./getstarted.component.scss']
})
export class GetStartedComponent implements OnInit {
	content: Requirements;

	constructor(
		private requirementsService: RequirementsService,
		private titleService: Title,
		private meta: Meta
	) {
		this.meta.updateTag({ name: 'description', content: 'Who can apply? Anyone interested in a career in Auto Technology, from graduating high school or community college students, to current dealership employees.' });
	}

	ngOnInit() {
		this.requirementsService.getRequirements().subscribe(data => this.content = data[0]);

		this.titleService.setTitle("Get Started | Nissan Technician Training Academy");
	}
}
