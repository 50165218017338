import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { Faq } from './faq';
import { FaqsService } from '../../services/faqs.service';
import { Title } from '@angular/platform-browser';
import { Meta } from '@angular/platform-browser';
import { GoogleAnalyticsEventTrackingService } from '../../services/google-analytics-event-tracking.service';

@Component({
	selector: 'faqs',
	templateUrl: './faqs.component.html',
	styleUrls: ['./faqs.component.scss'],
	providers: [GoogleAnalyticsEventTrackingService]
})
export class FaqsComponent implements OnInit {
	faqs: Faq[];
	selectedCard: number = 0;
	firstCard: number = 0;

	constructor(
		private faqsService: FaqsService,
		private titleService: Title,
		private meta: Meta,
		private gaEventTrackingSvc: GoogleAnalyticsEventTrackingService,
	) {
		this.meta.updateTag({ name: 'description', content: 'Questions about the NISSAN Technician Training Academy? Find answers and general information about the program quickly here.' });
	}

	ngOnInit() {
		this.faqsService.getFaqs().subscribe(faqs => this.faqs = faqs);
		this.titleService.setTitle("FAQs | Nissan Technician Training Academy");
	}

	setCard(card) {
		this.selectedCard = card;
		this._trackEvent('faq', 'faq', card, 'click');
	}


	public _trackEvent(component: string, destination: string, element: string, category: string) {
		this.gaEventTrackingSvc._trackEvent(component, destination, element, category);
	}
}