import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Benefit } from '../models/benefit';
import { ApiManagerService } from './api-manager.service';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class BenefitsService {
    private benefitsUrl = 'benefits/getbyprogramid';
    private host: string;

    constructor(
        private http: HttpClient,
        private apiSvc: ApiManagerService,
        ) {
            this.host = this.apiSvc.getApiHostBase();
        }

    getBenefits(programId: number): Observable<Benefit[]> {
        return this.http.get<BenefitsResponse>(this.host + this.benefitsUrl + '?programid=' + programId).pipe(
            map(res => {
                // check validity
                if ((res.success !== undefined && !res.success) || res.data === undefined) {
                    throw (res.message || 'Invalid Response');
                }
                return res.data;
            })
        );
    }
}

interface BenefitsResponse {
    success: boolean;
    message: string;
    data?: Benefit[];
}
