import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'error-404-page',
    templateUrl: './error-404-page.component.html',
    styleUrls: ['./error-404-page.component.scss']
})
export class Error404PageComponent implements OnInit {

    constructor(public activatedRoute: ActivatedRoute,
        private titleService: Title) { }

    ngOnInit() {
        this.titleService.setTitle('Page Not Found | Nissan Technician Training Academy');
    }

}
