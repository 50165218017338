import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { PhonePipe } from './pipes/phone.pipe';
import { OrderByPipe } from './pipes/orderby.pipe';
import { StatePipe } from './pipes/state.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { HrefFilterWithAnalyticsDirective } from './directives/href-filter-with-analytics.directive';

@NgModule({
    declarations: [
        SafeUrlPipe,
        SafeHtmlPipe,
        PhonePipe,
        OrderByPipe,
        StatePipe,
        HrefFilterWithAnalyticsDirective,
    ],
    imports: [
        CommonModule
    ],
    exports: [
        SafeUrlPipe,
        SafeHtmlPipe,
        PhonePipe,
        OrderByPipe,
        StatePipe,
        HrefFilterWithAnalyticsDirective,
    ],
})
export class CoreModule { }
