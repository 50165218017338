import { Injectable, Injector } from '@angular/core';
import { Observable, ReplaySubject, of } from 'rxjs';
import { switchMap, map, take } from 'rxjs/operators';
import { DealershipInfo } from '../models/dealership-info';
import { UserInfo } from '../models/user-info';
import { TokenUserDataProviderService } from './token-user.data-provider.service';
import { DealershipsDataProviderService } from './dealerships.data-provider.service';
import { Dealer } from '../components/dealer-locator/dealer';
import { BRAND_ID } from '../shared/brand-id.enum';
import { TokenUserInfo } from '../models/token-user-info';

@Injectable({
    providedIn: 'root',
})
export class CurrentTokenUserService {
    private associatedDealerships = new ReplaySubject<DealershipInfo[]>(1);
    private selectedDealership = new ReplaySubject<DealershipInfo>(1);

    constructor(
        private userSvc: TokenUserDataProviderService,
        private dealershipsSvc: DealershipsDataProviderService,
    ) {}

    public updateCurrenUserToken(urlToken: string) {
        if (!!urlToken) {
            // store or update the token in localstorage
            localStorage.setItem('token', urlToken);
            localStorage.setItem('ssouser', 'true');
        }
    }

    public setCurrentUser() {
        const storedToken = localStorage.getItem('token');
        if (!storedToken) {
            this.associatedDealerships.next(null);
            this.selectedDealership.next(null);
            return;
        }
        // call getTokenUser every time to confirm token is valid
        this.userSvc.getTokenUserInfo(storedToken).pipe(
            switchMap(userInfo => {
                const associatedDealershipCodes = !!userInfo.allowedDealerships ? userInfo.allowedDealerships.split(',') : [];
                const existingPrimaryDealershipCodeIndex = associatedDealershipCodes.indexOf(userInfo.primaryDealershipCode);
                if (!!userInfo.primaryDealershipCode && existingPrimaryDealershipCodeIndex !== 0) {
                    associatedDealershipCodes.unshift(userInfo.primaryDealershipCode);

                    // deduplicate
                    if (existingPrimaryDealershipCodeIndex > 0) {
                        // index + 1 because of unshift()
                        associatedDealershipCodes.splice(existingPrimaryDealershipCodeIndex + 1, 1);
                    }
                }
                // uncomment to test multiple dealerships
                // associatedDealershipCodes.push('3049', '5605');

                let dealershipInfoObs: Observable<[DealershipInfo[], DealershipInfo]>;
                if (associatedDealershipCodes.length < 1) {
                    dealershipInfoObs = of([[], undefined]);
                } else {
                    dealershipInfoObs = this.dealershipsSvc.getFilteredDealerships(associatedDealershipCodes.join(',')).pipe(
                        map(dealers => {
                            const mappedDealers = this.mapDealershipInfo(dealers);
                            // in case order is not preserved, try to find dealership that was requested first; fallback to first returned
                            return [
                                mappedDealers,
                                mappedDealers.find(d => d.dealerCode === associatedDealershipCodes[0]) || mappedDealers[0]
                            ];
                        }),
                    );
                }
                return dealershipInfoObs;
            }),
        ).subscribe(([associatedDealerships, selectedDealership]) => {
            this.associatedDealerships.next(associatedDealerships);
            this.selectedDealership.next(selectedDealership);
        });
    }

    public getCurrentTokenUser(): Observable<TokenUserInfo> {
        // need to hit token user endpoint every time to make sure token still valid
        const storedToken = localStorage.getItem('token');
        if (!storedToken) {
            return of(null);
        }
        return this.userSvc.getTokenUserInfo(storedToken);
    }

    public getCurrentDealerUser(): Observable<UserInfo> {
        return this.getSelectedDealership().pipe(
            switchMap(selectedDealershipInfo => {
                if (!selectedDealershipInfo) {
                    // not logged in, so no selected dealership
                    return of(null);
                }
                return this.getCurrentTokenUser().pipe(
                    map(tokenUserInfo => {
                        return tokenUserInfo.dealerUserInfo.find(u => u.dealerCode === selectedDealershipInfo.dealerCode)
                        || tokenUserInfo.dealerUserInfo.find(u => u.dealerCode === tokenUserInfo.primaryDealershipCode)
                        || tokenUserInfo.dealerUserInfo[0];
                    }),
                );
            }),
        );
    }

    public getAssociatedDealerships(): Observable<DealershipInfo[]> {
        return this.associatedDealerships.asObservable();
    }

    public getSelectedDealership(): Observable<DealershipInfo> {
        return this.selectedDealership.asObservable();
    }

    public updateSelectedDealership(dealership: DealershipInfo): void {
        this.associatedDealerships.pipe(
            take(1),
        ).subscribe(dealerships => {
            if (!!dealerships.find(d => d.dealerCode === dealership.dealerCode)) {
                this.selectedDealership.next(dealership);
            }
        });
    }

    public denyAccess(): void {
        this.userSvc.denyAccess();
    }

    public isLoggedIn(): boolean {
        return !!localStorage.getItem('token');
    }

    public logOut(): void {
        localStorage.removeItem('token');
        localStorage.removeItem('ssouser');
        this.setCurrentUser();
    }

    private mapDealershipInfo(rawDealerInfo: Dealer[]): DealershipInfo[] {
        const mappedData = rawDealerInfo.map(d => {
            return {
                name: d.name,
                dealerCode: d.dealerCode,
                region: '',
                phoneNumber: d.phone,
                address1: d.address1,
                address2: d.address2,
                city: d.city,
                state: d.state,
                zipCode: d.zip,
                brandId: d.brandId || BRAND_ID.UNSET
            };
        });
        return mappedData;
    }

    public getDealerSalesLocationDetails(primaryDealershipCode){
        return this.dealershipsSvc.getDealerSalesLocationDetails(primaryDealershipCode);
    }
}
