import { Component, OnInit } from '@angular/core';
import { GoogleAnalyticsEventTrackingService } from '../../services/google-analytics-event-tracking.service';
import { Testimonial } from '../../models/testimonial';
import { TestimonialsService } from '../../services/testimonials.service';

@Component({
    selector: 'home-testimonial',
    templateUrl: './home-testimonial.component.html',
    styleUrls: ['./home-testimonial.component.scss']
})
export class HomeTestimonialComponent implements OnInit {
    testimonial: Testimonial;

    constructor(
        private testimonialsSvc: TestimonialsService,
		private gaEventTrackingSvc: GoogleAnalyticsEventTrackingService,
    ) { }

    ngOnInit() {
        this.testimonialsSvc.getTestimonialById(1).subscribe(testimonial => {
            this.testimonial = testimonial;
        });
    }

    public _trackEvent(component: string, destination: string, element: string, category: string) {
		this.gaEventTrackingSvc._trackEvent(component, destination, element, category);
	}
}
