import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Testimonial } from '../models/testimonial';
import { environment } from '../../environments/environment';
import { ApiManagerService } from './api-manager.service';
import { TESTIMONIALS } from '../shared/mock-testimonials';

@Injectable({
    providedIn: 'root'
})
export class TestimonialsService {
    private testimonialsUrl = 'https://localhost:44332/api/testimonials';
    private host: string;

    constructor(
        private http: HttpClient,
        private apiSvc: ApiManagerService,
    ) {
        this.host = this.apiSvc.getApiHostBase();
    }

    getTestimonials(): Observable<Testimonial[]> {
        // return this.http.get<Testimonial[]>(this.host + this.testimonialsUrl + '/' + environment.brandId);
        return of(TESTIMONIALS);
    }

    getTestimonialById(testimonialId: number): Observable<Testimonial> | undefined {
        return of(TESTIMONIALS.find(t => t.id === testimonialId));
    }
}
