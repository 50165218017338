import { Component, OnInit } from '@angular/core';
import { Benefit } from '../../models/benefit';
import { BenefitsService } from '../../services/benefits.service';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'benefits',
    templateUrl: './benefits.component.html',
    styleUrls: ['./benefits.component.scss']
})
export class BenefitsComponent implements OnInit {
    benefits: Benefit[];

    constructor(
        private benefitsService: BenefitsService,
        private titleService: Title
    ) { }

    ngOnInit() {
        this.getBenefits();
        this.titleService.setTitle('Benefits | Nissan Technician Training Academy');
    }

    getBenefits(): void {
        this.benefitsService.getBenefits(1)
            .subscribe(benefits => this.benefits = benefits);
    }
}
