import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { BenefitsComponent } from './components/benefits/benefits.component';
import { ProgramComponent } from './components/program/program.component';
import { EnrollmentHowTosComponent } from './components/enrollmenthowtos/enrollmenthowtos.component';
import { NewsComponent } from './components/news/news.component';
import { FaqsComponent } from './components/faqs/faqs.component';
import { JobsComponent } from './components/jobs/jobs.component';
import { CollegeLocatorComponent } from './components/college-locator/college-locator.component';
import { DealerLocatorComponent } from './components/dealer-locator/dealer-locator.component';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { GetStartedComponent } from './components/getstarted/getstarted.component';
import { Error404PageComponent } from './components/error-404-page/error-404-page.component';
import { DealerPortalAuthGuard } from './services/dealer-portal-auth.guard';
import { TestimonialsComponent } from './components/testimonials/testimonials.component';

const routes: Routes = [
    { path: '', component: HomeComponent },
    // { path: 'careers', component: JobsComponent },
    // { path: 'why', component: BenefitsComponent },
    { path: 'locations', component: CollegeLocatorComponent },
    { path: 'dealers', component: DealerLocatorComponent },
    { path: 'program', component: ProgramComponent },
    // { path: 'enroll', component: EnrollmentHowTosComponent },
    // { path: 'contact', component: ContactFormComponent },
    // { path: 'contact/:id', component: ContactFormComponent },
    // { path: 'news', component: NewsComponent },
    { path: 'faqs', component: FaqsComponent },
    { path: 'getstarted', component: GetStartedComponent },
    { path: 'testimonials', component: TestimonialsComponent },
    {
        path: 'dealerportal',
        loadChildren: () => import('./dealer-portal/dealer-portal.module').then(mod => mod.DealerPortalModule),
        canLoad: [DealerPortalAuthGuard],
    },
    { path: '**', component: Error404PageComponent }
];

export const RoutingConfig = RouterModule.forRoot(routes);
