import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { News } from '../components/news/news';
import { ApiManagerService } from './api-manager.service';

@Injectable({
    providedIn: 'root'
})
export class NewsService {
    private newsUrl = 'news';
    private host: string;

    constructor(
        private http: HttpClient,
        private apiSvc: ApiManagerService,
    ) {
        this.host = this.apiSvc.getApiHostBase();
    }

    getNews(): Observable<News[]> {
        return this.http.get<News[]>(this.host + this.newsUrl);
    }
}
