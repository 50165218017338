import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PartnerLead } from '../models/interested-partner';
import { ApiManagerService } from './api-manager.service';

@Injectable({
    providedIn: 'root'
})
export class LearnMoreService {
    private learnmoreUrl = 'mail/learnmore';
    private host: string;

    constructor(
        private http: HttpClient,
        private apiSvc: ApiManagerService,
    ) {
        this.host = this.apiSvc.getApiHostBase();
    }

    addLead(lead: PartnerLead): Promise<any> {
        return this.http.post(this.host + this.learnmoreUrl, lead).toPromise()
            .then(this.extractData)
            .catch(this.handleErrorObservable);
    }

    extractData(_res: Response) {
        return 'success';
    }
    handleErrorObservable(error: Response | any) {
        console.error(error.message || error);
        return error.message || error;
    }
}
