import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { Testimonial } from '../../models/testimonial';
import { TestimonialsService } from '../../services/testimonials.service';
import { TestimonialVideoComponent } from '../testimonial-video/testimonial-video.component';

@Component({
    selector: 'testimonials',
    templateUrl: './testimonials.component.html',
    styleUrls: ['./testimonials.component.scss']
})
export class TestimonialsComponent implements OnInit {
    @ViewChildren(TestimonialVideoComponent) testimonialVideoElems: QueryList<TestimonialVideoComponent>;
    testimonials: Testimonial[];
    apprenticeTestimonial: Testimonial;
    hiringTestimonial: Testimonial;
    nissanTestimonial: Testimonial;
    activeVideoSubj: Subject<number> = new Subject<number>();

    constructor(
        private testimonialsService: TestimonialsService,
        private titleService: Title
        ) {}

    ngOnInit() {
        this.getTestimonials();

        this.titleService.setTitle('Testimonials | Nissan Technician Training Academy');
    }

    getTestimonials(): void {
        this.testimonialsService.getTestimonials()
            .subscribe(testimonials => {
                this.testimonials = testimonials;
                this.apprenticeTestimonial = testimonials.find(t => t.id === 3);
                this.hiringTestimonial = testimonials.find(t => t.id === 2);
                this.nissanTestimonial = testimonials.find(t => t.id === 1);
            });
    }

    onVideoPlayStarted(videoId: number) {
        this.activeVideoSubj.next(videoId);
    }
}
