import { Component, OnInit } from '@angular/core';
import { Stat } from '../../models/stat';
import { StatsService } from '../../services/stats.service';

@Component({
  selector: 'stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss']
})
export class StatsComponent implements OnInit {
	stats: Stat[];

	constructor(private statsService: StatsService) { }

	ngOnInit() {
		this.getStats();
	}

	getStats(): void {
		this.statsService.getStats()
		  .subscribe(stats => this.stats = stats);
	}
}
