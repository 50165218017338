import { Directive, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleAnalyticsEventTrackingService } from '../../services/google-analytics-event-tracking.service';

@Directive({
  selector: '[nttaHrefFilterWithAnalytics]'
})
export class HrefFilterWithAnalyticsDirective {
  @Input() analyticsLabel: string;

  constructor(
    private router: Router,
    private gaEventTrackingSvc: GoogleAnalyticsEventTrackingService,
  ) { }

  @HostListener('click', ['$event'])
  public onClick(event) {
    if (event.target.tagName === 'A') {
      this.handleAnchorClick(event.target, event);
    } else if ((event.target.tagName === 'IMG' || event.target.tagName === 'SVG') && event.target.parentElement.tagName === 'A') {
      this.handleAnchorClick(event.target.parentElement, event);
    } else {
      return;
    }
  }

  private handleAnchorClick(anchorElem: HTMLAnchorElement, event): void {
    if (this.isInternalLink(anchorElem)) {
      const internalTarget = anchorElem.pathname + anchorElem.search + anchorElem.hash;
      this.gaEventTrackingSvc._trackEvent(this.analyticsLabel, internalTarget);
      if(!anchorElem.pathname.endsWith("pdf")){
        this.router.navigateByUrl(internalTarget);
        event.preventDefault();
      }
      
    } else {
      this.gaEventTrackingSvc._trackEvent(this.analyticsLabel, 'external', anchorElem.href, 'navigation-external');
    }
  }

  private isInternalLink(anchorElem: HTMLAnchorElement): boolean {
    return (anchorElem.protocol == 'http:'
            || anchorElem.protocol == 'https:')
        && (!anchorElem.hostname
            || anchorElem.hostname.includes('localhost')
            || anchorElem.hostname.includes('nissantechacademy.com')
            || anchorElem.hostname.includes('techtrainingacademy')
            || anchorElem.hostname.includes('ntta-frontend-'));
  }
}
