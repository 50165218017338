import { Component, AfterViewInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, NavigationCancel, Params } from '@angular/router';
import { filter } from 'rxjs/operators';
import { googleAnalytics } from '../assets/script';
import { HttpParams } from '@angular/common/http';
import { CurrentTokenUserService } from './services/current-token-user.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
    private loading: boolean;
    title = 'Nissan Technician Training Academy';

    constructor(
        public router: Router,
        userSvc: CurrentTokenUserService
    ) {
        this.loading = true;
        
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(() => {
            window.scrollTo(0, 0);
        });

        // Google Analytics
        this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe(event => {
            const url = event['url'];
            if (url !== null && url !== undefined && url !== '' && url.indexOf('null') < 0) {
                googleAnalytics(url);
            }
        });
        // deal with SSO token and prime NNANet dealer user data if applicable
        // Can't wait for routing lifecycle since Header needs it and is outside the routeroutlet
        // get value of query param "token", or null if it's not there
        const curUrl = window.location.href;
        const httpParams = new HttpParams({ fromString: curUrl.split('?')[1] || '' });
        const urlToken: string = httpParams.get('token');
        userSvc.updateCurrenUserToken(urlToken);
        userSvc.setCurrentUser();
    }

    ngAfterViewInit() {
        this.router.events
            .subscribe((event) => {
                if (event instanceof NavigationStart) {
                    this.loading = true;

                    // clear token from Url, redirect to Dealerportal landing page
                    let httpParams = new HttpParams({ fromString: event.url.split('?')[1] || '' });
                    if (httpParams.has('token')) {
                        httpParams = httpParams.delete('token');
                        const queryParams: Params = httpParams.keys().reduce((params, k) => {
                            params[k] = httpParams.get(k);
                            return params;
                        }, {});
                        this.router.navigate(['/dealerportal'], {queryParams: queryParams, replaceUrl: true});
                    }
                    /* setTimeout(() => {
                      document.getElementById('ols__route__loader').scrollIntoView(true);
                    }, 5); */
                } else if (
                    event instanceof NavigationEnd ||
                    event instanceof NavigationCancel
                ) {
                    this.loading = false;
                }
            });
    }
}
