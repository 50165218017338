import { Component, OnInit } from '@angular/core';
import { GoogleAnalyticsEventTrackingService } from '../../services/google-analytics-event-tracking.service';

@Component({
	selector: 'home-information',
	templateUrl: './home-information.component.html',
	styleUrls: ['./home-information.component.scss'],
    providers: [GoogleAnalyticsEventTrackingService]
})
export class HomeInformationComponent implements OnInit {

	constructor(private gaEventTrackingSvc: GoogleAnalyticsEventTrackingService) { }

	ngOnInit() {
	}

	public _trackEvent(component: string, destination: string, element: string, category: string) {
		this.gaEventTrackingSvc._trackEvent(component, destination, element, category);
	}
}
