import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Feature } from '../components/features/feature';
import { environment } from '../../environments/environment';
import { ApiManagerService } from './api-manager.service';

@Injectable({
    providedIn: 'root'
})
export class FeaturesService {
    private featuresUrl = 'features';
    private host: string;

    constructor(
        private http: HttpClient,
        private apiSvc: ApiManagerService,
    ) {
        this.host = this.apiSvc.getApiHostBase();
    }

    getFeatures(): Observable<Feature[]> {
        return this.http.get<Feature[]>(this.host + this.featuresUrl + '/' + environment.brandId);
    }
}
