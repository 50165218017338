import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NttaAppConfig } from '../models/ntta-app-config';

@Injectable({
    providedIn: 'root'
})
export class ApiAuthService {
    private _apiToken: string;
    public get apiToken() {
        return this._apiToken;
    }

    constructor(
        private injector: Injector,
    ) { }

    async loadApiToken(appConfig: NttaAppConfig): Promise<any> {
        const http = this.injector.get(HttpClient);

        return http.post<{token: string}>(appConfig.api_host_base + 'Token/GetToken', appConfig.api_auth_credentials)
            .toPromise()
            .then(res => {
                this._apiToken = res.token;
            });
    }
}
