import { Component, OnInit, AfterViewInit, ViewChild, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GoogleMapsService } from '../../services/google-maps.service';
import { Title } from '@angular/platform-browser';
import { Meta } from '@angular/platform-browser';
import { LatLngBounds } from '@agm/core';
import { GoogleAnalyticsEventTrackingService } from '../../services/google-analytics-event-tracking.service';

import { Dealer } from './dealer';
import { DealershipsDataProviderService } from '../../services/dealerships.data-provider.service';

declare var google: any;

@Component({
    selector: 'dealer-locator',
    templateUrl: './dealer-locator.component.html',
    styleUrls: ['./dealer-locator.component.scss'],
    providers: [GoogleAnalyticsEventTrackingService]
})
export class DealerLocatorComponent implements OnInit, AfterViewInit {
    title = 'Dealer Locator Map';
    lat = 39;
    lng = -95;
    zoom = 5;
    dealers: Dealer[];
    selectedZip: string;
    brand = 1;
    useraction = false;
    selectedDealer = 0;
    mobile = false;
    selectedTab = 1;
    loading = false;
    smallView = false;
    invalidZip = false;
    geoAvailable = false;

    @ViewChild('AgmMap', { static: true }) private agmMap: any;

    constructor(
        private dealersService: DealershipsDataProviderService,
        public route: ActivatedRoute,
        private googleMaps: GoogleMapsService,
        private titleService: Title,
        private meta: Meta,
        private gaEventTrackingSvc: GoogleAnalyticsEventTrackingService
    ) {
        this.meta.updateTag({
            name: 'description',
            content: 'NISSAN Technician Training Academy (NTTA) prepares you for a career as a factory-trained technician at NISSAN and INFINITI dealerships nationwide.',
        });
    }

    @HostListener('window:resize') onResize() {
        this.resizeMap();
        if (window.innerWidth < 992) {
            this.mobile = true;
        } else {
            this.mobile = false;
        }
    }

    ngOnInit() {
        this.selectedZip = this.dealersService.getZip();
        this.brand = this.dealersService.getBrand();

        this.findDealersByZip();

        if (window.innerWidth < 992) {
            this.mobile = true;
        } else {
            this.mobile = false;
        }

        if ('geolocation' in navigator) {
            this.geoAvailable = true;
        }

        if (window.innerHeight < 480) {
            this.smallView = true;
        } else {
            this.smallView = false;
        }

        this.titleService.setTitle('Dealer Locator | Nissan Technician Training Academy');
    }

    ngAfterViewInit() {
        setTimeout(() => { this.resizeMap(); }, 5);
    }

    resizeMap() {
        const map__wrapper = document.getElementById('map__wrapper'),
            h = window.innerHeight,
            header = document.getElementsByTagName('app-header')[0],
            nav = header.querySelector('nav'),
            footer = document.getElementsByTagName('footer')[0];

        const mapHeight = h - nav.offsetHeight - ((window.innerWidth > 767) ? footer.offsetHeight : 0);

        if (mapHeight < 480) {
            map__wrapper.style.height = '480px';

        } else {
            map__wrapper.style.height = mapHeight + 'px';
        }

        const search = <HTMLElement>document.querySelector('.search-view'),
            dealerList = <HTMLElement>document.querySelector('.dealers'),
            listHeight = mapHeight - search.offsetHeight;

        if (listHeight < 480) {
            dealerList.style.height = '480px';
        } else {
            dealerList.style.height = listHeight + 'px';
        }

        // determine if the view height is too small for the map to show
        if (window.innerHeight < 480) {
            // this.smallView = true;
            map__wrapper.style.height = '90px';
        } else {
            this.smallView = false;
        }
    }

    getBrowserLocation() {
        // get the browser's location
        navigator.geolocation.getCurrentPosition((position) => {
            this.lat = position.coords.latitude;
            this.lng = position.coords.longitude;

            this.googleMaps.getStateFromCoordinates(this.lat, this.lng).subscribe(data => {
                let i = data.results[0].address_components.length;
                while (i--) {
                    if (data.results[0].address_components[i].types[0] == 'postal_code') {
                        this.selectedZip = data.results[0].address_components[i].long_name;
                        this.dealersService.setZip(this.selectedZip);
                        i = false;
                    }
                }
                // zoom into the dealers
                this.getDealers();
            });
        });

        this._trackEvent('dealerLocator', 'getDealers', 'currentLocation', 'click');
    }

    getDealers(): void {
        this.loading = true;

        this.invalidZip = false;

        this.dealersService.getDealersByZip()
            .subscribe(dealers => {
                // filter dealers less than or equal 100 miles away
                this.dealers = dealers; // .filter((d) => d.distance <= 100 || d.distance == undefined);
                this.fitOnMap();
                this.loading = false;
            });

        this.useraction = true;
        this._trackEvent('dealerLocator', 'getDealers', this.selectedZip + '_' + this.brand, 'search');
    }

    fitOnMap() {
        // fit the markers in the map
        if (this.dealers.length > 0) {
            const bounds: LatLngBounds = new google.maps.LatLngBounds();

            // add the user's location
            bounds.extend(new google.maps.LatLng(this.lat, this.lng));

            // add the dealer's location
            let i = 0;
            for (const mm of this.dealers) {
                if (mm.lat != 0 && mm.lng != 0) {
                    bounds.extend(new google.maps.LatLng(mm.lat, mm.lng));
                    i++;
                }
            }
            if (i > 0) {
                this.agmMap._mapsWrapper.fitBounds(bounds);
            } else {
                this.zoom = fiftyMileZoom;
            }
        }
    }

    findDealersByZip(): void {
        setTimeout(() => {
            this.dealersService.setBrand(this.brand);
            this.dealersService.setZip(this.selectedZip);

            if (/^[0-9]{5}$/.test(this.selectedZip)) {
                this.googleMaps.getCoordinates(this.selectedZip).subscribe(data => {
                    this.lat = data.results[0].geometry.location.lat;
                    this.lng = data.results[0].geometry.location.lng;

                    this.getDealers();
                });
            } else {
                this.invalidZip = true;
            }

        }, 1);
    }

    selectMarker(id) {
        this.selectedDealer = id;
        this._trackEvent('results', 'location', id, 'click');
    }

    isInfoWindowOpen(id) {
        return this.selectedDealer == id; // alternative: check if id is in array
    }

    refreshMap() {
        setTimeout(() => {
            this.fitOnMap();
        }, 20);
    }

    public _trackEvent(component: string, destination: string, element: string, category: string) {
        this.gaEventTrackingSvc._trackEvent(component, destination, element, category);
    }
}

const fiftyMileZoom = 10;
