import { Router, NavigationEnd } from '@angular/router';
import { Component, OnInit, HostListener, Input, ChangeDetectorRef } from '@angular/core';
import { GoogleAnalyticsEventTrackingService } from '../../services/google-analytics-event-tracking.service';
import { CurrentTokenUserService } from '../../services/current-token-user.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    providers: [GoogleAnalyticsEventTrackingService]
})
export class HeaderComponent implements OnInit {
    public url: string;
    activeInfiniti = false;
    mobileActive = false;
    mobile: boolean;
    hideNavButtons = false;
    headerStyle = 'transparent-header';
    isDealerportalUrl:boolean = false;
    isLoggedOutPage = false;
    loggedOutPageHomeLinkUrl: string = null;
    @Input() userFullName: string = null;

    constructor(
        private router: Router,
        private gaEventTrackingSvc: GoogleAnalyticsEventTrackingService,
        private userSvc: CurrentTokenUserService,
        private changeDetecter:ChangeDetectorRef
    ) {
    }

    ngOnInit() {
        this.router.events.subscribe((res) => {
            if (res instanceof NavigationEnd) {
                this.updateUrl(this.router.url);
            }
        });

        if (window.innerWidth < 992) {
            this.mobile = true;
        } else {
            this.mobile = false;
        }
    }

    @HostListener('window:resize') onResize() {
        if (window.innerWidth < 992) {
            this.mobile = true;
        } else {
            this.mobile = false;
        }
    }

    updateUrl(newUrl: string) {
        this.url = newUrl;

        this.hideNavButtons = this.url.toLocaleLowerCase().startsWith('/dealerportal');
        if (this.url.toLocaleLowerCase().includes('/logout')) {
            this.isLoggedOutPage = true;
            this.loggedOutPageHomeLinkUrl = this.getLoggedOutPageHomeLinkUrl();
        } else {
            this.isLoggedOutPage = false;
            this.loggedOutPageHomeLinkUrl = null;
        }
        this.headerStyle = this.getHeaderStyle();
        this.isDealerportalUrl = this.isDealerPortal();
    }
    isDealerPortal():boolean{
        if(this.url.search("dealerportal")>0){
        return true;
        }
        return false;
    }
    getHeaderStyle() {
        if (this.url !== '/') {
            return 'light-header';
        } else if (this.url == '/') {
            return 'transparent-header';
        } else {
            return 'dark-header';
        }
    }

    getHome() {
        return this.url === '/';
    }

    getIsLoggedIn() {
        return this.userSvc.isLoggedIn();
    }

    getLoggedOutPageHomeLinkUrl() {
        const curHref = window.location.href.toLocaleLowerCase();
        if (curHref.includes('techtrainingacademy.stage.nnanet.com') || curHref.includes('preprod.azurewebsites.net')) {
            return 'https://ntta-frontend-preprod.azurewebsites.net/';
        } else if (curHref.includes('techtrainingacademy.nnanet.com') || curHref.toLocaleLowerCase().includes('prod.azurewebsites.net')) {
            return 'https://www.nissantechacademy.com';
        }
        // Other domains should be safe to redirect to '/'
        return '/';
    }

    public _trackEvent(component: string, destination: string, element: string, category: string) {
        this.gaEventTrackingSvc._trackEvent(component, destination, element, category);
    }
}
