import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Requirements } from './../models/requirements';
import { ApiManagerService } from './api-manager.service';

@Injectable({
    providedIn: 'root'
})
export class RequirementsService {
    private benefitsUrl = 'requirements';
    private host: string;

    constructor(
        private http: HttpClient,
        private apiSvc: ApiManagerService,
    ) {
        this.host = this.apiSvc.getApiHostBase();
    }

    getRequirements(): Observable<Requirements[]> {
        return this.http.get<Requirements[]>(this.host + this.benefitsUrl + '/1');
    }
}
