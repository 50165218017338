import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Meta } from '@angular/platform-browser';
import { BenefitsService } from './../../services/benefits.service';
import { Benefit } from './../../models/benefit';

@Component({
    selector: 'home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    hero: Benefit;
    promos: Benefit[];

    constructor(
        private benefitsSvc: BenefitsService,
        private titleService: Title,
        private meta: Meta
    ) {
        this.meta.updateTag({
            name: 'description',
            // tslint:disable-next-line: max-line-length
            content: 'NISSAN Technician Training Academy (NTTA) prepares you for a career as a factory-trained technician at NISSAN and INFINITI dealerships nationwide.',
        });
    }

    ngOnInit() {
        this.benefitsSvc.getBenefits(1).subscribe(data => {
            this.hero = data[0];
            this.promos = data.slice(1, 5);
        });

        this.titleService.setTitle('Nissan Technician Training Academy');
    }
}
