import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as svg4everybody from 'svg4everybody';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

const bootApplication = () => {
  platformBrowserDynamic().bootstrapModule(AppModule)
    .then(() => {
      svg4everybody();
    })
    .catch(err => console.error(err));
};

if (environment.production) {
  enableProdMode();
}

if (document.readyState === 'complete') {
  bootApplication();
} else {
  document.addEventListener('DOMContentLoaded', bootApplication);
}
