import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Job } from '../components/jobs/job';
import { environment } from '../../environments/environment';
import { ApiManagerService } from './api-manager.service';

@Injectable({
    providedIn: 'root'
})
export class JobsService {
    private jobsUrl = 'jobs';
    private host: string;

    constructor(
        private http: HttpClient,
        private apiSvc: ApiManagerService,
    ) {
        this.host = this.apiSvc.getApiHostBase();
    }

    getJobs(): Observable<Job[]> {
        return this.http.get<Job[]>(this.host + this.jobsUrl + '/' + environment.brandId);
    }
}
