import { Injectable } from '@angular/core';
import { AppConfigService } from './app-config.service';

@Injectable({
    providedIn: 'root'
})
export class ApiManagerService {
    constructor(
        private environment: AppConfigService,
    ) { }

    getApiHostBase() {
        return this.environment.config.api_host_base;
    }
    getNnaNetUrl() {
        return this.environment.config.nna_net_url;
    }
    getApiAuthCredentials(): {username: string, password: string} {
        return this.environment.config.api_auth_credentials;
    }
    getAssetStorageHostBase() {
        return this.environment.config.asset_storage_host_base;
    }
    getIsLoaded(): boolean {
        return !!this.environment.config;
    }
}
