import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { EnrollmentHowTo } from '../components/enrollmenthowtos/enrollmenthowto';
import { ApiManagerService } from './api-manager.service';

@Injectable({
    providedIn: 'root'
})
export class EnrollmentHowTosService {
    private enrollmenthowtosUrl = 'enrollmenthowtos';
    private host: string;

    constructor(
        private http: HttpClient,
        private apiSvc: ApiManagerService,
    ) {
        this.host = this.apiSvc.getApiHostBase();
    }

    getEnrollmentHowTos(): Observable<EnrollmentHowTo[]> {
        return this.http.get<EnrollmentHowTo[]>(this.host + this.enrollmenthowtosUrl);
    }
}
